'use client';
import type {ReactNode} from 'react';
import {useEffect, useState} from 'react';
import * as serviceWorkerRegistration from './service-worker-registrations';


export type NotificationsProviderProps = {
    children: ReactNode;
    onPushSubscription: (subscription: PushSubscription) => void;
}

const TAG = 'ClientPushServiceProvider';

export const ClientPushServiceProvider = (props: NotificationsProviderProps) => {
    const {children, onPushSubscription} = props;

    const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration>();

    useEffect(() => {
        const onMessage = (event: MessageEvent) => {
            const {action, notificationId} = event.data;
            if (action === 'accept' && notificationId) {
                // do something with the action (implement it if required)
            }
            if (action === 'open') {
                // open a page based on the action
                window.location.assign(`/`);
            }
        };
        const onMessageError = (event: MessageEvent) => {
            console.error('event error', event);
        };

        if ('serviceWorker' in navigator) {
            serviceWorkerRegistration
                .register()
                .then((registration) => {
                    if (registration) {
                        setSwRegistration(registration);
                    }
                })
                .then(() => {
                    navigator.serviceWorker.addEventListener('message', onMessage);
                    navigator.serviceWorker.addEventListener('messageerror', onMessageError);
                }).catch((error) => {
                console.error(error);
            });

        }
        return () => {
            navigator.serviceWorker.removeEventListener('message', onMessage);
            navigator.serviceWorker.removeEventListener('messageerror', onMessageError);
        };
    }, []);

    useEffect(() => {
        if (!swRegistration?.active) {
            return;
        }

        if (typeof Notification === 'undefined') {
            return;
        }

        // Request the push notification permission from browser
        const status = Notification.permission;
        if (status === 'granted') {
            navigator.serviceWorker.ready
                .then(async registration => {
                    // Use the PushManager to get the user's subscription to the push service.
                    const subscription = await registration.pushManager.getSubscription();
                    if (subscription) {
                        return subscription;
                    }
                    const vapidKey = process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY ?? '';
                    const convertedVapidKey = urlBase64ToUint8Array(vapidKey);
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey
                    });
                }).then(subscription => {
                // Send the subscription details to the server using the Fetch API.
                onPushSubscription(subscription);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [swRegistration]);

    return (
        <div>
            {children}
        </div>
    );
};

const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};