'use client';
import { Label } from '../ui';
import { TranslatableTextProps } from './translatable-text-props';

/**
 * This component is used to display a translatable text on the client side.
 * To be used only with Translatable objects.
 */
export const ClientTranslatableText = (props: TranslatableTextProps) => {
    const { translatables, className, targetLang } = props;
    if (!translatables || translatables.length === 0) {
        return <div />;
    }
    if (!translatables || translatables.length === 0) {
        return <div />;
    }

    const matchedTranslatable = translatables.find(translatable => {
        if (!translatable.language) {
            console.warn('Translatable language is not set', translatable);
            return false;
        }
        return translatable.language.toLowerCase() === targetLang
    });
    const maybeHtml = matchedTranslatable?.value ?? (translatables[0]?.value ?? '');
    if (!maybeHtml) {
        return <div />;
    }

    return <Label suppressHydrationWarning={true} className={className} dangerouslySetInnerHTML={{ __html: maybeHtml }} />;
};

export const clientTranslatableText = (lang: string, translatables?: { language: string; value: string; }[]): string => {
    if (!translatables || translatables.length === 0) {
        return '';
    }
    const matchedTranslatable = translatables.find(translatable => {
        if (!translatable.language) {
            console.warn('Translatable language is not set', translatable);
            return false;
        }
        return translatable.language.toLowerCase() === lang
    });
    return matchedTranslatable?.value ?? (translatables[0]?.value ?? '');
}

export interface GetTargetTranslatableOpts {
    targetLang?: string;
    translatables?: { language: string; value: string; }[];
}

export const getTargetTranslatableText = (opts: GetTargetTranslatableOpts): string => {
    const { targetLang, translatables } = opts;
    if (!translatables || translatables.length === 0) {
        return '';
    }
    const lang = targetLang ?? 'ro';
    const matchedTranslatable = translatables.find(value => value.language.toLowerCase() === lang);
    return matchedTranslatable?.value ?? (translatables[0]?.value ?? '');
}
