import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../utils';
import { IconClose } from './icon-close';


const badgeVariants = cva(
    'inline-flex items-center rounded-full border border-button px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 hover:cursor-pointer',
    {
        variants: {
            variant: {
                default:
                    'border-transparent bg-button text-buttonText hover:bg-button/80',
                secondary:
                    'border-transparent bg-button text-buttonText hover:bg-button/80',
                destructive:
                    'border-transparent bg-buttonDanger text-buttonDangerText hover:bg-buttonDanger/80',
                outline: 'text-buttonText',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    }
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
    icontype?: 'close';
}

function Badge({ className, variant, ...props }: BadgeProps) {
    const { children, icontype } = props;
    return (
        <div className={cn(badgeVariants({ variant }), className)} {...props} >
            {children}
            {icontype && icontype === 'close' && <IconClose className="h-3 w-3 ml-1 fill-buttonText" />}
        </div>
    );
}

export interface IconBadgeProps
    extends BadgeProps {
    icon: JSX.Element;
}

function IconBadge({ className, variant, ...props }: IconBadgeProps) {
    const { children, icontype, icon } = props;
    return (
        <div className={cn(badgeVariants({ variant }), className)} {...props} >
            <div className={'mr-1'}>
                {icon}
            </div>
            {children}
            {icontype && icontype === 'close' && <IconClose className="h-3 w-3 ml-1 text-buttonText" />}
        </div>
    );
}

export { Badge, IconBadge, badgeVariants };
