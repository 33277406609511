// First, declare the new method on the String interface
declare global {
    interface String {
        replaceHTMLCharacters(): string;
    }
}

// Then, add the method to the String prototype
String.prototype.replaceHTMLCharacters = function (this: string): string {
    return this
        // Remove span tags but keep their content
        .replace(/<span[^>]*>(.*?)<\/span>/gi, '$1')
        // Replace HTML entities
        .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/gi, (match, entity) => {
            const entities: Record<string, string> = {
                'amp': '&',
                'lt': '<',
                'gt': '>',
                'quot': '"',
                'apos': "'",
                'eacute': 'é',
                'egrave': 'è',
                'agrave': 'à',
                'acirc': 'â',
                'ecirc': 'ê',
                'icirc': 'î',
                'ocirc': 'ô',
                'ucirc': 'û',
                'nbsp': ' ',
                'deg': '°',
                'rsquo': '’',
                'lsquo': '‘',
                'middot': '·',
                'mdash': '—',
                'mdash;': '—',
                'ndash': '–',
                'hellip': '…',
                'oelig': 'œ',
                'OElig': 'Œ',
                'Yuml': 'Ÿ',
                'bull': '•',
            };
            return entities[entity] || match;
        })
        .replace(/\n/g, '<br>');
};